import mixpanel from 'mixpanel-browser'
import { ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { API_HOST, ENVIRONMENT, MIXPANEL_ENABLED } from 'src/config/config'
import { useAuthenticated } from 'src/hooks/useAuthenticated'
import { AppState } from 'src/store/types'

type IMainProps = {
  meta: ReactNode | null
  children: ReactNode
}

const Main: React.FC<IMainProps> = ({ meta = null, children }) => {
  const authInfo = useSelector((state: AppState) => state.authInfo)

  const { status } = useAuthenticated()

  useEffect(() => {
    const userId = authInfo.id || undefined
    const userName = authInfo.name || undefined
    const userEmail = authInfo.email || undefined
    const companyName = authInfo.companyName || undefined

    if (MIXPANEL_ENABLED) {
      if (userEmail) {
        mixpanel.identify(userEmail)
        mixpanel.people.set({
          name: userName,
          email: userEmail,
          company: companyName,
          strapiUrl: `${API_HOST}/admin/content-manager/collectionType/plugin::users-permissions.user/${userId}`,
        })
      }
      mixpanel.set_group('environment', ENVIRONMENT || 'develop')
    }
  }, [status])

  return (
    <div className="antialiased w-full h-screen overflow-y-scroll font-secondary">
      {meta}
      {(process.env.NODE_ENV === 'development' || typeof window !== 'undefined') && (
        <ToastContainer
          progressClassName="toastProgress"
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastStyle={{
            borderRadius: '8px',
            color: '#011D58',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: '500',
          }}
        />
      )}
      <div className="w-full h-full">{children}</div>
    </div>
  )
}

export { Main }
